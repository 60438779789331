
import { defineComponent, onMounted, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { translate, statusColor } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPackagePlatform } from "@/core/services/HelpersFun";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import platform from "@/components/Platform.vue";
import NotConnectedPlatform from "@/components/NotConnectedPlatform.vue";
import JwtService from "@/core/services/JwtService";
import UserAvatar from "@/components/UserAvatar.vue";

export default defineComponent({
  name: "kt-otp-management-table",
  components: {
    platform,
    NotConnectedPlatform,
    UserAvatar,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const loading = ref(true);
    const bots_type = ref("buy");
    const pagination = ref();
    const page = ref(1);
    const botList = ref();
    const only_online = ref();
    const buy_with_discount = ref();
    const status = ref();

    const getItems = (type) => {
      var typeData;
      if (type === "buy") {
        typeData = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          only_bought_bots: 1,
          only_active_bots: 1,
          only_online: only_online.value ? 1 : 0,
          buy_with_discount: buy_with_discount.value ? 1 : 0,
          status: status.value ? "ready" : "",
        };
      }
      if (type === "free") {
        typeData = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          free_bots: 1,
          only_online: only_online.value ? 1 : 0,
          status: status.value ? "ready" : "",
        };
      }
      if (type === "expired") {
        typeData = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          expiration_type: "expired ",
          buy_with_discount: buy_with_discount.value ? 1 : 0,
        };
      }

      loading.value = true;
      ApiService.query("admin/reports/bots", {
        params: typeData,
      })
        .then(({ data }) => {
          console.log(data.data);
          loading.value = false;
          botList.value = data.data.bots.data;
          pagination.value = data.data.bots.pagination;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const excelLink = computed(() => {
      var params;
      if (bots_type.value === "buy") {
        params = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          only_bought_bots: 1,
          only_active_bots: 1,
          only_online: only_online.value ? 1 : 0,
          buy_with_discount: buy_with_discount.value ? 1 : 0,
          status: status.value ? "ready" : "",
        };
      }
      if (bots_type.value === "free") {
        params = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          free_bots: 1,
          only_online: only_online.value ? 1 : 0,
          status: status.value ? "ready" : "",
        };
      }
      if (bots_type.value === "expired") {
        params = {
          with_platforms: true,
          with_user: true,
          page: page.value,
          expiration_type: "expired ",
          buy_with_discount: buy_with_discount.value ? 1 : 0,
        };
      }
      var queryString = Object.keys(params)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&");

      return (
        process.env.VUE_APP_BACK_URL +
        `bots/download?api_token=${JwtService.getToken()}&${queryString}`
      );
    });

    const allPlatformsHolder = platforms();
    const { platformsList } = storeToRefs(allPlatformsHolder);

    const checkNotActivePackagePlatform = (packagePlatforms, platforms) => {
      const connectedPlatforms =
        platforms !== null && platforms?.length > 0
          ? platforms.map((pl) => {
              return pl.platform;
            })
          : [];

      if (connectedPlatforms !== null && packagePlatforms.length > 0)
        return packagePlatforms.filter((x) => !connectedPlatforms.includes(x));
      return connectedPlatforms;
    };

    const handleSizeChange = (val: number) => {
      return;
    };

    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems(bots_type.value);
    };

    onMounted(() => {
      getItems(bots_type.value);
      setCurrentPageBreadcrumbs(translate("گزارش اشتراک ها"), []);
    });

    return {
      loading,
      pagination,
      getItems,
      botList,
      translate,
      statusColor,
      bots_type,
      platformsList,
      excelLink,

      only_online,
      buy_with_discount,
      status,

      handleSizeChange,
      handleCurrentChange,
      checkPackagePlatform,
      checkNotActivePackagePlatform,
    };
  },
});
